<template>
  <v-app >
    <template>
      <dashboard-core-app-bar />
      <dashboard-core-drawer />
      <dashboard-core-view style="background-color:white" />
      <!-- <dashboard-core-settings /> -->
    </template>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
    },
    mounted(){
    },
    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
